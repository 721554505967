import { NotificationTypes } from "../../enums/notificationTypes";

export const ReferViewConstants = {
  PAGE_TITLE: "Invite Friends",
  INVITE_FRIENDS_DESCRIPTION: "Invite friends by copying and sharing the invite link or by entering their email ids as comma seperated below",
  EMAIL_FIELD_PLACEHOLDER: "Enter comma separated email ids (e.g. abc@gmail.com, def@gmail.com, xyz@gmail.com)",
  SEND_INVITES_TO_EMAIL: "Send invites to email",
  COPY_INVITE: "Copy invite",
  VALID_EMAIL_ERROR: "Please provide a valid email",
  NOTIFICATION: {
    FETCH_INVITE_LINK_ERROR: {
      type: NotificationTypes.ERROR,
      message: "Something went wrong",
      description: "Unable to fetch the invite link"
    },
    COPY_INVITE_LINK_MESSAGE: {
      type: NotificationTypes.SUCCESS,
      message: "Invite link copied",
      description: "Successfully copied invite link to clipboard!"
    },
    COPY_INVITE_LINK_ERROR: {
      type: NotificationTypes.ERROR,
      message: "Failed to copy invite link",
      description: "Unable to copy invite link, Please try again!"
    }
  }
};