import React, { FC, useEffect } from "react";
import { useState } from "react";
import SubscriptionService from "../../../services/SubscriptionService/subscription.service";
import ErrorMessage from "../../../shared/components/Error";
import PageHeader from "../../../shared/components/PageHeader";
import { REGEX } from "../../../shared/utils/regex";
import { ReferViewConstants } from "../../../shared/constants/referViewConstants";
import ButtonComponent from "../../../shared/components/ButtonComponent";
import Notification from "../../../shared/components/Notification";
import "./refer.scss";

const Refer: FC = () => {
  const [emailFieldState, setEmailFieldState] = useState<{
    value?: string;
    isValid?: boolean;
  }>({ value: "", isValid: true });

  const {
    createInvite,
    loading,
    getInviteLink,
    inviteLinkLoading,
    inviteLink,
  } = SubscriptionService();

  useEffect(() => {
    getInviteLink(true);
  }, []);

  const {
    PAGE_TITLE,
    INVITE_FRIENDS_DESCRIPTION,
    EMAIL_FIELD_PLACEHOLDER,
    SEND_INVITES_TO_EMAIL,
    COPY_INVITE,
    VALID_EMAIL_ERROR,
    NOTIFICATION,
  } = ReferViewConstants;

  const validateEmail = (email: string) => REGEX.SEPERATE_EMAIL.test(email);

  const updateEmail = (event?: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event?.target.value || "";
    setEmailFieldState({ value, isValid: validateEmail(value) });
  };

  const validateUserInput = () => {
    setEmailFieldState((prevState) => ({
      ...prevState,
      isValid: validateEmail(prevState.value || ""),
    }));
  };

  const submitHandler = async () => {
    const formattedEmail = emailFieldState.value?.trim();
    if (!formattedEmail || !emailFieldState.isValid) {
      validateUserInput();
      return;
    }

    const response = await createInvite(formattedEmail);
    if (response) {
      setEmailFieldState({ value: "", isValid: true });
    }
  };

  const copyToClipBoard = async () => {
    if (inviteLink) {
      try {
        await navigator.clipboard.writeText(inviteLink);
        Notification(NOTIFICATION.COPY_INVITE_LINK_MESSAGE);
      } catch (error) {
        Notification(NOTIFICATION.COPY_INVITE_LINK_ERROR);
      }
    } else {
      const response = await getInviteLink();
      if (response) {
        copyToClipBoard();
      } else {
        Notification(NOTIFICATION.COPY_INVITE_LINK_ERROR);
      }
    }
  };

  return (
    <div className="refer">
      <div className="refer__header">
        <PageHeader title={PAGE_TITLE} />
      </div>
      <div className="refer__container">
        <div className="refer__container__content">
          <p className="refer__container__content__invite-friends-description">
            {INVITE_FRIENDS_DESCRIPTION}
          </p>
          <div className="refer__container__content__mail-form">
            <div className="refer__container__content__mail-form__field-wrapper">
              <textarea
                className={`refer__container__content__mail-form__field-wrapper__email-field ${
                  !emailFieldState.isValid && "error-border"
                }`}
                placeholder={EMAIL_FIELD_PLACEHOLDER}
                onBlur={validateUserInput}
                onChange={updateEmail}
                value={emailFieldState.value}
                disabled={loading || inviteLinkLoading}
              />
              <ErrorMessage
                className="refer__container__content__mail-form__field-wrapper__email-field-error"
                message={!emailFieldState.isValid ? VALID_EMAIL_ERROR : ""}
              />
            </div>
            <ButtonComponent
              type="primary"
              onClick={submitHandler}
              loading={loading}
              disabled={inviteLinkLoading}
            >
              {SEND_INVITES_TO_EMAIL}
            </ButtonComponent>
            <ButtonComponent
              className="refer__container__content__mail-form__copy-link-btn"
              type="ghost"
              onClick={copyToClipBoard}
              iconClass="link-icon"
              loading={inviteLinkLoading}
              disabled={loading}
            >
              {COPY_INVITE}
            </ButtonComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refer;
