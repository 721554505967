import {
  Button,
  Form,
  Input,
  List,
  Modal,
  Radio,
  RadioChangeEvent,
  Skeleton,
} from "antd";
import React, { ChangeEvent, FC, Fragment, useEffect, useState } from "react";
import { numberConvention } from "../../utils/numberConvention";
import WalletIcon from "./../../../assets/icons/multi color icons - svg/dynamic wallet -  white.svg";
import WalletStaticIcon from "./../../../assets/icons/multi color icons - svg/static wallet - white.svg";
import "./styledModalPlaceOrder.scss";
import { Formik } from "formik";
import DynamicText from "../DynamicText/index";
import { User } from "../../../models/user.model";
import CustomerService from "../../../services/CustomerService/customer.service";
import {
  ShareBreakdown,
  ShareUnit,
  SingleShareCalculation,
} from "../../../models/Share/share.model";
import Notification from "../Notification";
import { Wallet } from "../../../models/Wallet/wallet.model";
import { useHistory } from "react-router-dom";
import { OrderText } from "../../constants/orderText.constant";

const { TITLE: confirmOrderTitle } = OrderText;

interface StyleModalProps {
  visible?: boolean;
  title: string;
  dynamicWalletBalance: number;
  staticWalletBalance: number;
  okText: string;
  className?: string;
  confirmLoading?: boolean;
  onCancel: () => void;
  maskClosable?: boolean;
  contentTemplate?: any;
  buyMode?: boolean;
  user?: User;
  wallet?: Wallet;
}

const StyledModalPlaceOrder: FC<StyleModalProps> = (props) => {
  const {
    visible,
    title,
    dynamicWalletBalance,
    staticWalletBalance,
    className,
    confirmLoading = false,
    okText,
    onCancel,
    maskClosable = false,
    buyMode,
    user,
    wallet,
  } = props;

  const history = useHistory();
  const customerId = history.location.pathname.split("/").pop();
  const {
    loading,
    getBuySharesBreakdown,
    getSellSharesBreakdown,
    setShare,
    share,
    placeOrder,
  } = CustomerService();
  const [selectedShare, setSelectedShare] = useState<ShareUnit>();
  const [calcData, setCalcData] = useState<SingleShareCalculation>();
  const [selectedShareUnit, setSelectedShareUnit] = useState<number>();
  const [buyShareOption, setBuyShareOption] = useState<number>(1);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      handleSelectShare(Number(selectedShareUnit), true);
    }, 1500);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [selectedShareUnit]);

  useEffect(() => {
    if (selectedShare?.unit) {
      setSelectedShareUnit(selectedShare?.unit);
    }
  }, [selectedShare?.unit]);

  useEffect(() => {
    if (buyMode === undefined) {
      return;
    } else {
      setSelectedShareUnit(undefined);
      setShare(new ShareBreakdown());
    }
  }, [buyMode]);

  const handleSelectShare = (share: number, isInput = false) => {
    if (
      loading ||
      (!buyMode && (share || 0) > (wallet?.sellableHoldings || 0))
    ) {
      return Notification({
        message: "",
        description: `Maximum available units to sell is ${wallet?.sellableHoldings} units`,
        type: "info",
      });
    }
    if (buyMode && (selectedShare?.unit || 0) + (share || 0) >= 100000) {
      return Notification({
        message: "",
        description: "Max limit is 1,00,000 units",
        type: "info",
      });
    }
    setSelectedShare((prev) => {
      const unit = isInput ? share || 0 : (prev?.unit || 0) + (share || 0);
      const totalAmount = unit * (calcData?.currentPrice || 0);
      let amount = 0;
      if (buyMode) {
        amount = +(
          totalAmount +
          (totalAmount * (calcData?.buyPercent || 0)) / 100
        ).toFixed(2);
      } else {
        amount = amount = +(
          totalAmount -
          (totalAmount * (calcData?.sellPercent || 0)) / 100
        ).toFixed(2);
      }
      if (unit) {
        if (buyMode) {
          if (buyShareOption == 1) {
            getBuySharesBreakdown(
              Number(customerId),
              buyShareOption,
              undefined,
              unit
            );
          } else {
            getBuySharesBreakdown(
              Number(customerId),
              buyShareOption,
              unit,
              undefined
            );
          }
        } else {
          if (customerId) {
            getSellSharesBreakdown(Number(customerId), unit.toString());
          }
        }
      }

      return {
        unit,
        amount,
      };
    });
    // setTotalShares(prev => prev + (share || 0))
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedShareUnit(Number(event.target.value));
  };

  const buyUnitOptions = [
    { label: "Units", value: 1 },
    { label: "Amount", value: 0 },
  ];

  const handleBuyShareUnits = ({ target: { value } }: RadioChangeEvent) => {
    setSelectedShareUnit(undefined);
    setBuyShareOption(value);
    setShare(new ShareBreakdown());
  };

  const breakdownTemplate = share?.breakdown?.map((breakdown, i) => (
    <List.Item>
      <span>{breakdown[0]}</span>
      <span
        className={
          (share.breakdown?.length === i + 1 ? " text-bold " : " ") +
          (breakdown[1] < "0" ? " danger " : "")
        }
      >
        {breakdown[1]}
      </span>
    </List.Item>
  ));

  const handleConfirmation = () => {
    if (buyMode) {
      placeOrder(Number(customerId), { unit: share?.unit, type: true });
    } else {
      placeOrder(Number(customerId), { unit: share?.unit, type: false });
    }
  };

  const content = (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={() => {}}
    >
      {({ setFieldValue, values, resetForm, errors }) => {
        return (
          <Fragment>
            <div className="main-content">
              <p className="title">
                {confirmOrderTitle}
              </p>
              {share?.amount && !loading ? (
                <div className="main-content__amount-container">
                  <h1>
                    <DynamicText
                      text={`${share?.unit} Units`}
                      width="100%"
                      fontSize={32}
                    />
                  </h1>
                  <h1>
                    <DynamicText
                      text={`${numberConvention(share?.amount)} KES`}
                      width="100%"
                      fontSize={32}
                    />
                  </h1>
                </div>
              ) : (
                <Skeleton.Input active={true} size="large" />
              )}
            </div>
            <div className="content-template">
            <List>
                  {share?.breakdown && !loading
                    ? breakdownTemplate
                    : new Array(4).fill(" ").map((_) => (
                      <List.Item>
                        <Skeleton.Input active={true} size="small" />
                        <Skeleton.Input
                          active={true}
                          size="small"
                          className="mini-skeleton"
                        />
                      </List.Item>
                    ))}
                </List>
              <div className="footer">
                <Form>
                  <div className="styled-modal-btn">
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="middle"
                      loading={loading}
                      onClick={() => handleConfirmation()}
                      disabled={share?.unit === undefined}
                    >
                      Send Confirmation link to customer
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Fragment>
        );
      }}
    </Formik>
  );
  return (
    <Modal
      className={"styled-modal " + className}
      title={
        <Fragment>
          <h2>{title}</h2>
          <div>
            <img src={WalletIcon} alt="dynamic" />
            <p className="text">Dynamic Wallet Balance </p>
            <p className="balance">
              {numberConvention(dynamicWalletBalance)} KES
            </p>
          </div>
          <div>
            <img src={WalletStaticIcon} alt="dynamic" />
            <p className="text">Static Wallet Balance </p>
            <p className="balance">
              {numberConvention(staticWalletBalance)} KES
            </p>
          </div>
          {buyMode ? (
            <>
              <div className="buy-share__input-container">
                <Input
                  bordered={true}
                  allowClear={false}
                  type="number"
                  value={selectedShareUnit === 0 ? '' : selectedShareUnit}
                  onChange={handleInputChange}
                  className="buy-share__input"
                />
                <Radio.Group
                  options={buyUnitOptions}
                  optionType="button"
                  buttonStyle="solid"
                  onChange={handleBuyShareUnits}
                  defaultValue={buyUnitOptions[0].value}
                  className="shares__details--unitContainer"
                />
              </div>
            </>
          ) : (
            <>
              <div className="buy-share__input-container">
                <Input
                  bordered={true}
                  allowClear={false}
                  type="number"
                  value={selectedShareUnit === 0 ? "" : selectedShareUnit}
                  onChange={handleInputChange}
                  className="buy-share__input"
                  min={0}
                />
                <Radio.Group
                  options={buyUnitOptions}
                  optionType="button"
                  buttonStyle="solid"
                  disabled={true}
                  defaultValue={buyUnitOptions[0].value}
                  className="shares__details--unitContainer"
                />
              </div>
            </>
          )}
        </Fragment>
      }
      visible={visible}
      onOk={() => {}}
      maskClosable={maskClosable}
      okText={okText}
      destroyOnClose
      onCancel={onCancel}
      confirmLoading={confirmLoading}
    >
      {content}
    </Modal>
  );
};

export default StyledModalPlaceOrder;
